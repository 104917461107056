<template>
  <div class="page_centerDetail">
    <top :bgWhite="true"></top>
    <div class="contentAll padding-lr-30 top-80 padding-bottom-60">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/my">个人中心</el-breadcrumb-item>
        <el-breadcrumb-item>赛事详情</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <p class="fs11 text-333 bold "><span class="point">个人中心</span><span> > 赛事详情</span></p> -->
      <div class="margin-top-20 bg-white padding content-box">
        <div class="bg-white flex">
          <div class="">
            <el-image
              :src="data.thumb"
              class="block-91 radius-5 margin-right-20"
              fit="cover"
            ></el-image>
          </div>
          <div class="">
            <div class="">
              <p class="fs11">{{ data.event_name }}</p>
              <p class="text-666 fs9 margin-top-5">
                赛事类型：{{ data.event_type }}
              </p>
            </div>
            <div class="flex align-center margin-top-15">
              <img
                src="../../assets/image/icon/push.png"
                class="block-18 margin-right-5"
              />
              <p class="fs9 text-666">发布时间：{{ data.create_time }}</p>
            </div>
            <div class="flex align-center margin-top-10">
              <img
                src="../../assets/image/icon/time.png"
                class="block-18 margin-right-5"
              />
              <p class="fs9 text-666">结束时间：{{ data.end_time }}</p>
            </div>
            <div class="margin-top-20">
              <p class="bold fs11">赛事简介</p>
              <p class="fs9 text-666 margin-top-20" v-html="data.body"></p>
            </div>
          </div>
        </div>
        <!-- 按钮 -->
        <div class="flex justify-center margin-top-100">
          <el-button
            type="danger"
            @click="toEnroll"
            round
            :disabled="data.is_match == 1 ? true : false"
            >{{ data.is_match == 1 ? "已报名" : "立即报名" }}</el-button
          >
        </div>
      </div>
    </div>
    <bottom :bgWhite="true"></bottom>
  </div>
</template>

<script>
import top from "@/components/top";
import bottom from "@/components/bottom";
export default {
  components: {
    bottom,
    top,
  },
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    this.getGameDetail();
  },
  methods: {
    // 获取赛事详情
    getGameDetail() {
      this.ajax(
        "post",
        "/v1/613b1a90be5ba",
        {
          event_aid: this.$route.query.event_aid,
        },
        (res) => {
          this.isInit = true;
          if (res.code == 1) {
            this.data = res.data;
          } else {
            this.$message.error(res.msg);
          }
        },
        (err) => {
          console.log(err);
          this.hadGotCode = false;
        }
      );
    },
    // 赛事周期资格判断
    getTimeCircle() {
      return new Promise((resolve, reject) => {
        this.ajax(
          "post",
          "/v1/621737cf18e11",
          {
            event_aid: this.$route.query.event_aid,
          },
          (res) => {
            resolve(res.code);
          },
          (err) => {
            console.log(err);
          }
        );
      });
    },
    // 去报名
    async toEnroll() {
      let can_join = await this.getTimeCircle();
      if (can_join != 1) {
        this.$message.error("暂时不能参加");
        return;
      }
      if (this.data.is_match && this.data.is_match == 1) return;
      this.$router.push({
        path: "/pushWorks",
        query: {
          event_aid: this.$route.query.event_aid,
        },
      });
    },
  },
};
</script>

<style  lang="scss">
html,
body {
  background: #f6f7f9 !important;
  .content {
    width: 100%;
    background: #ffffff;
  }
}
.contentAll {
  width: 60%;
}
.content-box {
  width: 100%;
}
.cover-img {
  width: 100%;
  height: 88px;
  background: rgba(0, 0, 0, 0);
  opacity: 1;
  border-radius: 5px 5px 0px 0px;
}
</style>